
import {
  ref,
  onMounted,
  getCurrentInstance,
  reactive
} from 'vue'
import {
  useStore
} from 'vuex'
import QrcodeVue from 'qrcode.vue'
import {
  getDetailAgen,
  scanQrProfileAgent,
  authUseCase
} from '@/domain/usecase'
import print from 'vue3-print-nb'

export default {
  components: {
    /** Tambahkan component Vue-Qr-Code */
    QrcodeVue
  },
  directives: {
    print
  },
  setup() {
    const store = useStore()
    const app = getCurrentInstance()
    const {
      $toast,
      $strInd
    } = app!.appContext.config.globalProperties
    const dataAgent = ref()
    const valueQR = ref('')

    const getEncryptQr = async () => {
      scanQrProfileAgent().then((result: any) => {
        valueQR.value = result.result.value
      })
    }

    /** Fungsi get data untuk ambil data detail agen  */
    const getDataAgen = () => {
      store.dispatch('showLoading')
      authUseCase.getAgentInfo()
        .then(({
          error,
          result
        }) => {
          if (error) {
            $toast.add({
              severity: 'error',
              detail: $strInd.toast.errorAllData,
              group: 'bc',
              life: 3000
            })
          } else {
            dataAgent.value = result
            valueQR.value = result.QrCodeData
          }
          store.dispatch('hideLoading')
        })
    }

    /** fungsi untuk print halaman */
    const printWQr = () => ({
      id: 'printQrProfileAgent',
      popTitle: '',
      beforeOpenCallbac: (vue: any) => {
        store.dispatch('showLoading')
      },
      openCallback: (vue: any) => {
        store.dispatch('hideLoading')
      },
    })

    onMounted(() => {
      getDataAgen()
    })
    return {
      store,
      dataAgent,
      valueQR,
      printWQr
    }
  }

}
